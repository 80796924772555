export default {
  component: () => import('./page.vue'),
  path: '/ecg/history/:userId?',
  name: 'ecg-history',
  props: true,
  meta: {
    title: '历史记录',
    auth: true,
  },
};
