export default {
  component: () => import('./page.vue'),
  path: '/weekly/:pid',
  alias: '/weekly/next/:pid',
  name: 'weekly',
  props: true,
  meta: {
    title: '日常健康监测服务',
    auth: true,
  },
};
