export default {
  component: () => import('./page.vue'),
  path: '/archive/:userId?',
  name: 'archive',
  props: true,
  meta: {
    title: '健康档案',
    auth: true,
  },
};
