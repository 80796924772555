import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration.js';
import isoWeek from 'dayjs/plugin/isoWeek.js';
import updateLocale from 'dayjs/plugin/updateLocale.js';

dayjs.extend(duration);
dayjs.extend(isoWeek);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekdays: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
});

export { dayjs };

export function weekFormat(timestamp) {
  return timestamp ? dayjs(timestamp).format('dddd') : undefined;
}

export function datetimeFormat(timestamp) {
  return timestamp ? dayjs(timestamp).format('YYYY-MM-DD HH:mm') : undefined;
}

export function timeFormat(timestamp) {
  return timestamp ? dayjs(timestamp).format('HH:mm') : undefined;
}

export function dateFormat(timestamp) {
  return timestamp ? dayjs(timestamp).format('YYYY-MM-DD') : undefined;
}

export function dateFormatZH(timestamp) {
  return timestamp ? dayjs(timestamp).format('YYYY 年 M 月 D 日') : undefined;
}

export function durationFormat(milliseconds = 0) {
  return milliseconds
    ? milliseconds < 1000
      ? '00:00'
      : dayjs.duration(milliseconds).format('mm:ss')
    : '';
}

export function durationHumanFormat(milliseconds = 0) {
  return milliseconds
    ? milliseconds < 1000
      ? '不到一秒'
      : dayjs
          .duration(milliseconds)
          .format('H 小时 m 分 s 秒')
          .replace(/^0\s小时\s/, '')
          .replace(/^0\s分\s/, '')
    : '0 秒';
}

export function tooLong(milliseconds = 0) {
  return milliseconds >= 15 * 60 * 1000;
}

export function phoneMask(value) {
  return /^(\+86)?1(\d{10})$/.test(value)
    ? `${value.slice(0, -8)}****${value.slice(-4)}`
    : value;
}

export function setTitle(title) {
  document.title = `${title} | ${
    import.meta.env.WATCHING ? '本地调试中' : '心相随'
  }`;
}

export function money(value) {
  return `￥${(value / 100).toFixed(2)}`;
}
