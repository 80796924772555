import Eruda from 'eruda';

import { isInWechat, isIOS } from '@eaphone/env';

if (isIOS && isInWechat) {
  // iOS 返回问题
  window.addEventListener('pageshow', (e) => {
    if (e.persisted) {
      window.location.reload();
    }
  });
}

const Storage = import.meta.env.DEV ? localStorage : sessionStorage;

const key = 'show-eruda';

export function toggle() {
  Storage.setItem(key, Storage.getItem(key) === 'true' ? 'false' : 'true');
  window.location.reload();
}

if (
  !import.meta.env.WATCHING &&
  (import.meta.env.DEV || Storage.getItem(key) === 'true')
) {
  Eruda.init();
}
