export const route = {
  component: () => import('./page.vue'),
  path: '/hotel/help',
  name: 'hotel-help',
  meta: {
    title: '帮助',
    auth: true,
    needPhone: false,
    wechat: {
      AppMessageShare: false,
      TimelineShare: false,
      baseAuth: true,
    },
  },
};

export const tab = {
  label: route.meta.title,
  icon: 'question-o',
  to: {
    name: route.name,
  },
};
