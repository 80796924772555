export default {
  component: () => import('./page.vue'),
  path: '/manual/overview/:userId?',
  props: true,
  name: 'manual-overview',
  meta: {
    title: '指标记录',
    auth: true,
  },
};
