import { pickBy } from 'lodash';

import { route as Devices } from '../../main/devices/route.config.js';
import { route as Home } from '../../main/home/route.config.js';
import { route as Mine } from '../../main/mine/route.config.js';
import { route as Records } from '../../main/records/route.config.js';
import { route as ReportsHejia } from '../../main/reports/route.config.js';

import component from './page.vue';

const children = [Home, Records, ReportsHejia, Devices, Mine];

export default {
  component,
  path: '/(home|devices|records|reports|mine)',
  children: children.map(({ path, props, ...rest }) => ({
    ...rest,
    path,
    props:
      props === true && path.includes('?')
        ? (route) => pickBy(route.params, (item) => item !== '')
        : props,
  })),
  name: 'main',
};
