import { parseRoute } from '@eaphone/utils';

export const route = parseRoute({
  component: () => import('./page.vue'),
  loader: () => import('./loader.js'),
  path: '/hotel/intro',
  name: 'hotel-intro',
  meta: {
    title: '健康智能马桶',
    auth: true,
    needPhone: false,
    wechat: {
      AppMessageShare: false,
      TimelineShare: false,
      baseAuth: true,
    },
  },
});

export const tab = {
  label: '首页',
  icon: 'home-o',
  to: {
    name: route.name,
  },
};
