export default {
  component: () => import('./page.vue'),
  path: '/trial/:code',
  name: 'trial',
  props: true,
  meta: {
    title: '订阅本次结果',
    auth: true,
    needPhone: false,
  },
};
