export default {
  component: () => import('./page.vue'),
  path: '/arrhythmia/:batchId',
  name: 'arrhythmia',
  meta: {
    title: '心律失常风险单次提示',
    auth: true,
    wechat: {
      AppMessageShare: false,
      TimelineShare: false,
    },
  },
};
