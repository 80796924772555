export default {
  component: () => import('./page.vue'),
  path: '/manual/:userId?',
  props: true,
  name: 'manual',
  meta: {
    title: '手动录入',
    auth: true,
  },
};
