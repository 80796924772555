export default {
  component: () => import('./page.vue'),
  path: '/message',
  name: 'message',
  props: true,
  meta: {
    title: '消息信息',
    auth: true,
  },
};
