import ky from 'ky';
import { pickBy } from 'lodash';

import { captureException } from '@eaphone/sentry';
import { Auth } from '@eaphone/storage';

const statusErrors = {
  400: '发送的请求参数不正确',
  401: '登录超时，请重新登录',
  403: '没有访问该功能的权限',
  404: '找不到您要访问的内容',

  // 人为触发，只是利用其编号：
  408: '网络连接超时，请重试',
  415: '返回的数据格式不正确',
  418: '未知错误，请求失败',
  421: '服务暂时不可用',
};

/** 错误预设生成 */
export class KyError extends Error {
  constructor(
    code = 418,
    message = statusErrors[code] ?? statusErrors[418],
    rest = {},
  ) {
    super(message);
    this.code = code;
    this.name = 'KyError';
    this.info = rest;
  }
}

function mergeErrorJson({
  errcode,
  errCode,
  errmsg,
  errMsg,
  error,
  errorMsg,
  message,
  success,
  ...rest
}) {
  return {
    ...rest,
    errCode: errCode ?? errcode,
    error: success === false || error === true,
    message: message ?? errorMsg ?? errMsg ?? errmsg,
  };
}

function toJson(response) {
  return response.json().then(mergeErrorJson);
}

function typeCheck(type) {
  return (response) => {
    const ContentType = response.headers.get('Content-Type');

    return ContentType && ContentType.startsWith(type);
  };
}

const isJson = typeCheck('application/json');

export const beforeRequest = [
  function cleanSearchParams(request, options) {
    if (options.searchParams) {
      // eslint-disable-next-line no-param-reassign
      options.searchParams = pickBy(
        options.searchParams,
        (item) => item !== undefined,
      );
    }
  },
  async function autoAddToken(request, options) {
    if (options.auth !== false) {
      // 请求前检测 token，通过 auth: false 可关闭此功能。
      const { token } = Auth;

      if (token) {
        // 默认检测 token 是否存在，存在则在请求头中添加 token。
        request.headers.set('Authorization', `Bearer ${token}`);
      } else {
        Auth.clear();
        // token 不存在时，取消请求并报错。
        throw new KyError(401);
      }
    }
  },
];

function haveResponse(request, response) {
  return (
    response.ok &&
    !['delete', 'head'].includes(request.method) &&
    response.status !== 204 &&
    response.body !== null
  );
}

export const afterResponse = [
  async function handleHttpError(request, options, response) {
    if (!response.ok) {
      if (response.status === 401) {
        // 返回 401 时删除本地 Token
        Auth.clear();
      }

      // 抛出 http 错误
      if (response.body !== null && isJson(response)) {
        const { message, errCode } = await toJson(response);

        if (message === 'invalid token' || errCode === 5) {
          // fork 401
          Auth.clear();
          throw new KyError(401);
        }

        if (message) {
          throw new KyError(response.status, message);
        }
      }

      throw new KyError(response.status);
    }
  },
  async function handle(request, options, response) {
    if (haveResponse(request, response) && isJson(response)) {
      const {
        error = false,
        message,
        errCode,
        ...rest
      } = await toJson(response);

      if (message === 'invalid token') {
        // fork 401
        Auth.clear();
        throw new KyError(401);
      }

      if (error) {
        // 检查 error 或 success 字段
        throw new KyError(418, message, {
          errCode,
          ...rest,
        });
      }
    }
  },
];

const Ky = ky.create({
  timeout: 10000,
  prefixUrl: import.meta.env.API_BASE_URL, // .best-shot/env.toml
  retry: 0,
  hooks: { beforeRequest, afterResponse },
});

function KyPureError(error) {
  if (error.name === 'AbortError') {
    return '请求已取消'; // 智能错误信息
  }

  if (error instanceof KyError) {
    if (error.code === 401) {
      Auth.clear();
    } else {
      captureException(error, {
        tags: { scene: 'KyError' },
      });
    }
  }

  if (error.name === 'TimeoutError' || error.message === 'Failed to fetch') {
    throw new KyError(408); // 连接超时
  }

  captureException(error, {
    // 记录未知错误
    tags: { scene: 'KyErrorUnknown' },
  });

  throw error;
}

export const KyJson = new Proxy(
  {},
  {
    // eslint-disable-next-line consistent-return
    get(_, key) {
      if (['get', 'post', 'put', 'patch', 'delete'].includes(key)) {
        return (input, { transform = (io) => io?.data, ...options } = {}) => {
          if (options.searchParams) {
            // eslint-disable-next-line no-param-reassign
            options.searchParams = pickBy(
              options.searchParams,
              (item) => item !== undefined,
            );
          }

          return Ky[key](input, options)
            .json()
            .then(transform)
            .catch(KyPureError);
        };
      }

      if (key === 'file') {
        return (input, { file, ...options }) => {
          const formData = new FormData();

          formData.append('file', file, file.name);

          return KyJson.post(input, {
            ...options,
            body: formData,
          });
        };
      }
    },
  },
);
