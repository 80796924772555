import {
  closeToast,
  showConfirmDialog,
  showDialog,
  showLoadingToast,
} from 'vant';

export const status = {
  waiting: '待付款',
  completed: '已完成',
  canceled: '已取消',
};

export function askCancel(orderNo, callback) {
  showConfirmDialog({
    title: '确定要取消订单吗？',
    message: `订单号：${orderNo}`,
  })
    .then(() => {
      callback(orderNo);
    })
    .catch(() => {});
}

export function askPay(callback1, callback2) {
  showConfirmDialog({
    title: '订单支付',
    message: '仅支持微信支付',
  })
    .then(() => {
      callback1();
    })
    .catch(() => {
      callback2();
    });
}

export function askBack(looper, callback) {
  showConfirmDialog({
    title: '已完成支付？',
  })
    .then(() => {
      showLoadingToast('正在查询');

      looper(callback)
        .then((final) => {
          closeToast();

          if (final) {
            callback();
          } else {
            showDialog({
              title: '提示',
              message: '系统未能获取你的支付信息\n请稍后重试',
            })
              .then(() => {
                callback();
              })
              .catch(() => {});
          }
        })
        .catch(() => {
          callback();
          closeToast();
        });
    })
    .catch(() => {
      callback();
    });
}

function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export async function createLooper(fetcher, checker) {
  let count = 0;

  async function action() {
    const data = await fetcher();

    if (count < 5 && !checker(data)) {
      count += 1;

      await delay(1000);

      return action();
    }

    return checker(data);
  }

  return action();
}
