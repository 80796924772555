import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { defineStore, storeToRefs } from 'pinia';

import { is3rdView } from '@eaphone/env';
import { KyJson } from '@eaphone/network';
import { SentrySetUser } from '@eaphone/sentry';
import { Auth } from '@eaphone/storage';

const url = 'passport/v2/user/info';

export function getUserInfoBy(userId) {
  return KyJson.get(url, { searchParams: { userId } });
}

function getUserInfo() {
  return KyJson.get(url);
}

function postUserInfo(json) {
  return KyJson.patch(url, { json });
}

function avatarUpload(file, userId) {
  return KyJson.file(`passport/v1/user/${userId}/avatar`, { file });
}

function forMe({ userId, avatarUrl }, { id, name, remark, ...rest } = {}) {
  return id === 'guest'
    ? { id, name: '临时访客', ...rest }
    : id === userId && !is3rdView
      ? { id, name: '我', ...rest, avatarUrl }
      : { id, name: remark ?? name, ...rest };
}

export const useProfileStore = defineStore('profile', {
  state: () => ({
    data: { ...Auth.info },
  }),
  actions: {
    forMe(list) {
      return Array.isArray(list)
        ? list.map((item) => forMe(this.data, item))
        : forMe(this.data, list);
    },
    setData(data) {
      this.data = data;

      SentrySetUser({
        id: data.userId,
        name: data.name,
      });

      Auth.info = data;
    },
    fetchData() {
      return getUserInfo().then((data) => {
        this.setData(data);

        return data;
      });
    },
    update(result) {
      return postUserInfo(result).then((data) => {
        this.setData(data);

        return data;
      });
    },
    setAvatar(file) {
      const { userId } = this.data;

      return avatarUpload(file, userId).then(() => {
        this.fetchData();
      });
    },
  },
});

export function useProfile() {
  const store = useProfileStore();

  const io = storeToRefs(store);

  const route = useRoute();

  onMounted(() => {
    if (route.meta.auth && !store.data.id) {
      store.fetchData().catch(() => {});
    }
  });

  return io.data;
}
