export const route = {
  component: () => import('./page.vue'),
  path: '/arrhythmia/history/:type(0|1|week)?',
  name: 'arrhythmia-history',
  props: true,
  meta: {
    title: '心电',
    auth: true,
  },
};

export const tab = {
  label: route.meta.title,
  icon: 'like-o',
  to: {
    name: route.name,
  },
};
