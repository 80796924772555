import { defineStore } from 'pinia';

import { KyJson } from '@eaphone/network';
import { localStore } from '@eaphone/storage';

function getFamilies() {
  return KyJson.get('family/v1/family/group/');
}

function getFamily(id) {
  return KyJson.get(`family/v1/family/${id}/`);
}

function createFamily(name) {
  return KyJson.post('family/v1/family/', {
    searchParams: { name },
  });
}

function renameFamily({ familyId, name }) {
  return KyJson.patch(`family/v1/family/${familyId}/name/`, {
    searchParams: { name },
  });
}

function removeFamily(familyId) {
  return KyJson.delete(`family/v1/family/${familyId}/`);
}

function removeMeFromFamily(familyId) {
  return KyJson.delete(`family/v1/family/${familyId}/member/exit/`);
}

function removeMemberFromFamily({ familyId, memberId }) {
  return KyJson.delete(`family/v1/family/${familyId}/member/${memberId}/`);
}

function editRemark({ familyId, memberId, remark }) {
  return KyJson.patch(
    `family/v1/family/${familyId}/member/${memberId}/remark/`,
    {
      searchParams: { remark },
    },
  );
}

function addMemberToFamilyByName({ familyId, name }) {
  return KyJson.post(`family/v1/family/${familyId}/member/virtual`, {
    json: { name },
  });
}

function moveDeviceToFamily({ familyId, serial, to }) {
  return KyJson.patch(
    `family/v1/device/serial/${serial}/transfer/${familyId}/${to}`,
  );
}

function flatten({ joins = [], creates = [] } = {}) {
  return [...creates, ...joins];
}

export const useFamilyStore = defineStore('family', {
  state: () => ({
    data: {},
    temp: {},
    selected: localStore.get('family-selected'),
  }),
  getters: {
    all: ({ data }) => flatten(data),
    count() {
      return this.all.length;
    },
    current: ({ temp, selected }) => temp[selected],
    devices() {
      const { current: { devices = [] } = {} } = this;

      return devices;
    },
    members() {
      const { current: { members = [] } = {} } = this;

      return members;
    },
  },
  actions: {
    // eslint-disable-next-line consistent-return
    select(id) {
      this.selected = id;
      localStore.set('family-selected', id);

      if (id) {
        return this.getFamily(id);
      }
    },
    fetchData() {
      return getFamilies()
        .then((data) => {
          this.data = data;

          const list = flatten(data);

          const { selected } = this;

          if (list.some(({ id }) => selected === id)) {
            this.getFamily(selected);
          } else {
            this.select(list[0]?.id);
          }

          return data;
        })
        .catch((error) => {
          this.data = {};
          throw error;
        });
    },
    getFamily(id) {
      return getFamily(id).then((data) => {
        this.temp[data.id] = data;

        return data;
      });
    },
    getDevice(familyId) {
      return this.getFamily(familyId);
    },
    removeMeFromFamily(familyId) {
      return removeMeFromFamily(familyId).then(() => {
        this.fetchData();
      });
    },
    createFamily(name) {
      return createFamily(name).then((data) => {
        this.fetchData();

        return data;
      });
    },
    removeFamily(familyId) {
      return removeFamily(familyId).then(() => {
        this.fetchData();
      });
    },
    renameFamily({ familyId, name }) {
      return renameFamily({ familyId, name }).then(() => {
        this.fetchData();
      });
    },
    removeMemberFromFamily({ familyId, memberId }) {
      return removeMemberFromFamily({ familyId, memberId }).then(() => {
        this.getFamily(familyId);
      });
    },
    moveDeviceToFamily({ familyId, serial, to }) {
      return moveDeviceToFamily({ familyId, serial, to }).then(() => {
        this.getFamily(familyId);
      });
    },
    addMemberToFamilyByName({ familyId, name }) {
      return addMemberToFamilyByName({ familyId, name }).then(() => {
        this.getFamily(familyId);
      });
    },
    editRemark({ familyId, memberId, remark }) {
      return editRemark({ familyId, memberId, remark }).then(() => {
        this.getFamily(familyId);
      });
    },
  },
});
