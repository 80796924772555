export default {
  component: () => import('./page.vue'),
  name: 'goods',
  meta: {
    title: '心律失常风险分析周报',
    auth: true,
    wechat: {
      AppMessageShare: false,
      TimelineShare: false,
    },
  },
};
