export default {
  component: () => import('./page.vue'),
  path: '/trial/report/:batchId',
  name: 'trial-report',
  props: true,
  meta: {
    title: '数据分析报告',
    auth: true,
    needPhone: false,
    wechat: {
      AppMessageShare: false,
      TimelineShare: false,
    },
  },
};
