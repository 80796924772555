export const route = {
  component: () => import('./page.vue'),
  path: '/home',
  name: 'home',
  meta: {
    title: '健康',
    auth: true,
  },
};

export const tab = {
  label: route.meta.title,
  icon: 'shield-o',
  to: {
    name: route.name,
  },
};
