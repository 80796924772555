import { pickBy } from 'lodash';

import { route as Heat } from '../../hotel/heat/route.config.js';
import { route as Help } from '../../hotel/help/route.config.js';
import { route as Intro } from '../../hotel/intro/route.config.js';

import component from './page.vue';

const children = [Intro, Heat, Help];

export default {
  component,
  path: '/(intro|heat|help)',
  children: children.map(({ path, props, ...rest }) => ({
    ...rest,
    path,
    props:
      props === true && path.includes('?')
        ? (route) => pickBy(route.params, (item) => item !== '')
        : props,
  })),
  name: 'hotel',
};
