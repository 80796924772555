export default {
  component: () => import('./page.vue'),
  path: '/invitation-app/:id',
  name: 'invitation-app',
  props: true,
  meta: {
    title: '加入家庭组',
    wechat: {
      AppMessageShare: false,
      TimelineShare: false,
      openTagList: ['wx-open-launch-app'],
    },
  },
};
