export default {
  component: () => import('./page.vue'),
  path: '/heart/report/:batchId',
  name: 'heart-report',
  props: true,
  meta: {
    title: '智能分析',
    auth: true,
    wechat: {
      AppMessageShare: false,
      TimelineShare: false,
    },
  },
};
