export default {
  component: () => import('./page.vue'),
  path: '/device/:serial/records/:type(all|mine|unassigned)',
  name: 'device-records',
  props: true,
  meta: {
    title: '设备使用记录',
    auth: true,
  },
};
