import { status } from '../order/utils.js';

const types = Object.keys(status).join('|');

export default {
  component: () => import('./page.vue'),
  name: 'orders',
  path: `/orders/:type(${types})?`,
  props: true,
  meta: {
    title: '我的订单',
    auth: true,
  },
};
