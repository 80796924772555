import { isHejia } from '@eaphone/env';

export default {
  component:
    isHejia || import.meta.env.WATCHING
      ? () => import('./page.vue')
      : () => import('./cmcc.vue'),
  name: 'order',
  path: '/order/:orderNo',
  props: true,
  meta: {
    title: '订单详情',
    auth: isHejia || import.meta.env.WATCHING,
  },
};
