import { isHejia } from '@eaphone/env';

export const route = {
  component: isHejia
    ? () => import('../../pages/device/page.vue')
    : () => import('./page.vue'),
  path: '/devices',
  name: 'devices',
  meta: {
    title: '设备',
    auth: true,
    wechat: isHejia
      ? undefined
      : { openTagList: ['wx-open-launch-app', 'wx-open-launch-weapp'] },
  },
};

export const tab = {
  label: route.meta.title,
  icon: 'cluster-o',
  to: {
    name: route.name,
  },
};
