import { PetShop } from 'pet-shop';

const namespace = `xinxiangsui-${import.meta.env.CONFIG_NAME}`;

export const localStore = PetShop({
  namespace,
  storage: localStorage,
  json: true,
});

export const sessionStore = PetShop({
  namespace,
  storage: sessionStorage,
  json: true,
});

export function createCache(groups, storage = sessionStore) {
  return Object.defineProperties(
    {
      clear() {
        storage.clear();
      },
    },
    Object.fromEntries(
      Object.entries(groups).map(([key, name = key]) => [
        key,
        {
          get() {
            return storage.get(name);
          },
          set(value) {
            if (value || value === 0 || value === false) {
              storage.set(name, value);
            } else {
              storage.remove(name);
            }
          },
        },
      ]),
    ),
  );
}

export const Auth = createCache({
  token: 'token',
  tenant: 'tenant',
  code: 'code',
  info: 'info',
});

export const Experiments = createCache(
  { useMiniApp: 'use-mini-app' },
  localStore,
);

export const Temp = createCache({
  loop: 'loop',
  deviceId: 'deviceId',
});
