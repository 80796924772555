export default {
  component: () => import('./page.vue'),
  path: '/test',
  name: 'test',
  meta: {
    title: '测试',
    wechat: {
      openTagList: ['wx-open-launch-app', 'wx-open-launch-weapp'],
    },
  },
};
