import { tab as Heat } from '../../hotel/heat/route.config.js';
import { tab as Help } from '../../hotel/help/route.config.js';
import { tab as Intro } from '../../hotel/intro/route.config.js';

export const tabItems = [
  Intro,
  {
    label: '领取记录',
    icon: 'clock-o',
    to: {
      name: 'hotel-reports',
    },
    replace: false,
  },
  Heat,
  Help,
];
