import { KyJson } from '@eaphone/network';
import { Auth, sessionStore } from '@eaphone/storage';

export function login({ code, type }) {
  return KyJson.post('passport/v2/user/3rd-party/login', {
    auth: false,
    json: { code, type },
  }).then(({ token, needPhone } = {}) => {
    if (token) {
      Auth.token = token;

      if (!import.meta.env.WATCHING && needPhone) {
        sessionStore.set('need-phone', true);
      }
    } else {
      throw new Error('登录失败');
    }
  });
}
