export default {
  component: () => import('./page.vue'),
  path: '/ecg/:batchId',
  name: 'ecg',
  props: true,
  meta: {
    title: '心电',
    auth: true,
  },
};
