import { pickBy } from 'lodash';

import { defineAsyncComponent, h } from 'vue';

export { useFetcher } from './fetcher.js';

const Wrapper = defineAsyncComponent(() => import('./wrapper.vue'));

export function parseRoute({
  path,
  props,
  component,
  loader,
  placeholder,
  meta,
  ...config
}) {
  return {
    ...config,
    path,
    props:
      props === true && path.includes('?')
        ? (route) =>
            pickBy({ ...route.query, ...route.params }, (item) => item !== '')
        : props,
    meta,
    component: loader
      ? async () => h(Wrapper, { loader, component, placeholder })
      : component,
  };
}

export function routeMapper(routeMap) {
  return Object.entries(routeMap).map(
    ([defaultPath, { path = defaultPath, ...config }]) =>
      parseRoute({ path, ...config }),
  );
}
