export default {
  component: () => import('./page.vue'),
  path: '/invite/:familyId',
  name: 'invite',
  meta: {
    title: '邀请家庭组成员',
    auth: true,
    wechat: {
      AppMessageShare: true,
      TimelineShare: false,
    },
  },
};
