export default {
  component: () => import('./page.vue'),
  path: '/hotel/reports',
  name: 'hotel-reports',
  meta: {
    title: '领取记录',
    auth: true,
    needPhone: false,
    wechat: {
      AppMessageShare: false,
      TimelineShare: false,
      baseAuth: true,
      Favorite: true,
    },
  },
};
