import * as Sentry from '@sentry/vue';

export function SentryInit({ app, version, router }) {
  if (!import.meta.env.WATCHING) {
    Sentry.init({
      app,
      dsn: import.meta.env.SENTRY_DSN, // See this in .best-shot/env.toml
      release: import.meta.env.GIT_HASH,
      environment: import.meta.env.MODE,
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
      integrations: [
        Sentry.browserTracingIntegration({
          router,
        }),
        Sentry.replayIntegration(),
      ],
      initialScope: {
        tags: {
          platform: import.meta.env.CONFIG_NAME,
          version,
        },
      },
    });
  }
}

export function SentrySetUser({ username, id }) {
  if (username && id) {
    Sentry.withScope((scope) => {
      scope.setUser({ username, id });
    });
  }
}

export { captureException, captureMessage } from '@sentry/vue';
