export default {
  component: () => import('./page.vue'),
  path: '/history/:from(sensor|manual)/:sensorType/:userId',
  name: 'history',
  props: true,
  meta: {
    title: '历史记录',
    auth: true,
  },
};
