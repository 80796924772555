import stringify from 'qs/lib/stringify.js';

import { closeToast, showFailToast, showLoadingToast } from 'vant';

import { login } from '@eaphone/auth';
import { isAndroid, isInWechat } from '@eaphone/env';
import { captureException } from '@eaphone/sentry';
import { Auth, sessionStore } from '@eaphone/storage';

function wechatUrl(url, scope) {
  const params = stringify({
    appid: import.meta.env.APP_ID,
    redirect_uri: url,
    response_type: 'code',
    scope: `snsapi_${scope}`,
    state: '',
  });

  return `https://open.weixin.qq.com/connect/oauth2/authorize?${params}#wechat_redirect`;
}

function fullUrl(url) {
  return new URL( // eslint-disable-next-line no-undef
    __webpack_public_path__ + url.replace(/^\//, ''),
    window.location.origin,
  ).href;
}

function replace({ path, query }) {
  const io = stringify(query);
  window.location.replace(`${fullUrl(path)}?${io}`);
}

function loginLogic(next, to) {
  showLoadingToast('正在登录...');
  const {
    path,
    query: { code, state, ...query },
  } = to;
  login({ code, type: 'weixin' })
    .then(() => {
      closeToast();

      if (isAndroid) {
        next({ path, query, replace: true });
      } else {
        replace({ path, query });
      }
    })
    .catch((error) => {
      console.error(error);
      showFailToast(error.message);
      next({ name: 'error-401' });
    });
}

export function loginHook(to, from, next) {
  if (to.meta.auth) {
    if (to.query.code) {
      loginLogic(next, to);
    } else if (Auth.token) {
      // 已有 token
      if (
        to.meta.needPhone !== false &&
        to.name !== 'phone' &&
        sessionStore.has('need-phone')
      ) {
        sessionStore.set('phone-bind-from', to.fullPath);
        next({ name: 'phone', replace: true });
      } else {
        next();
      }
    } else if (isInWechat) {
      const url = wechatUrl(
        fullUrl(to.fullPath),
        to.meta.wechat?.baseAuth ? 'base' : 'userinfo',
      );
      window.location.replace(url);
    } else {
      next({ name: 'error-sdk', replace: true });
    }
  } else {
    next();
  }
}

export { usePageConfig } from './mixin.js';

const logo = new URL(
  '@eaphone/design-resource/商标/心相随/公众号.png',
  import.meta.url,
).href;

const shareInfo = {
  title: '心相随微信公众号',
  desc: '心相随，守护您的健康',
  link: window.location.origin,
  imgUrl: logo,
};

const sdk = {
  updateTimelineShareData(info) {
    wx.updateTimelineShareData({ ...shareInfo, ...info });
  },
  updateAppMessageShareData(info) {
    wx.updateAppMessageShareData({ ...shareInfo, ...info });
  },
};

document.addEventListener(
  'WeixinOpenTagsError',
  (event) => {
    captureException(new Error(event.detail.errMsg), {
      tags: {
        scene: 'WeixinOpenTagsError',
      },
      contexts: {
        detail: event.detail,
      },
    });
  },
  false,
);

export function sdkHook() {
  return new Promise((resolve, reject) => {
    wx.ready(() => {
      resolve(sdk);
    });
    wx.error((error) => {
      captureException(error, {
        tags: { wechat: 'jssdk' },
      });
      reject(new Error(error.errMsg));
    });
  });
}
