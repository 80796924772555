export default {
  component: () => import('./page.vue'),
  path: '/hotel/share/:batchId',
  name: 'hotel-share',
  props: true,
  meta: {
    title: '数据分析报告',
    auth: false,
    wechat: {
      AppMessageShare: false,
      TimelineShare: false,
    },
  },
};
