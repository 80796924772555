export default {
  component: () => import('./page.vue'),
  path: '/analysis/:sensorType(18|17|22|23|44|resp_rate)/:userId?',
  name: 'analysis',
  props: true,
  meta: {
    title: '健康数据',
    auth: true,
  },
};
