export default {
  component: () => import('./page.vue'),
  path: '/airkiss',
  name: 'airkiss',
  meta: {
    title: '设备配网',
    wechat: {
      jsApiList: ['configWXDeviceWiFi'],
      AppMessageShare: false,
      TimelineShare: false,
    },
  },
};
